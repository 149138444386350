import '@tensorflow/tfjs-backend-webgl';
import {
  DoubleSide,
  Mesh,
  MeshStandardMaterial,
  AmbientLight,
  SpotLight,
  Scene,
  WebGLRenderer,
  OrthographicCamera,
  PCFSoftShadowMap,
  sRGBEncoding,
} from 'three';
import * as FaceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import FaceMeshFaceGeometry from './FaceBufferedGeometry';
import Textures from './textures';

let scene;
let camera;
let renderer;

// Create material for mask.
const material = new MeshStandardMaterial({
  color: 0xffffff,
  roughness: 0.8,
  metalness: 0,
  map: null, // Set later by the face detector.
  side: DoubleSide,
  opacity: 1,
});

// Create a new geometry helper.
const faceGeometry = new FaceMeshFaceGeometry();

// Create mask mesh.
const mask = new Mesh(faceGeometry, material);
mask.receiveShadow = true;
mask.castShadow = true;

// Defines if the source should be flipped horizontally.
const flipCamera = true;
let referenceFace;

async function render(model) {
  // Wait for video to be ready (loadeddata).
  const video = document.querySelector('video');

  // Wait for the model to return a face.
  const faces = await model.estimateFaces({
    input: video,
    flipHorizontal: flipCamera,
  });

  // There's at least one face.
  if (faces.length > 0) {
    // Update face mesh geometry with new data.
    faceGeometry.update(faces[0], flipCamera);

    // Use the reference face texture coordinates for this face geometry.
    for (let j = 0; j < 468; j += 1) {
      const x = referenceFace.face.scaledMesh[j][0];
      const y = referenceFace.face.scaledMesh[j][1];
      faceGeometry.uvs[j * 2] = x;
      faceGeometry.uvs[j * 2 + 1] = 1 - y;
    }
    faceGeometry.getAttribute('uv').needsUpdate = true;
  }

  // Render the scene normally.
  renderer.render(scene, camera);

  requestAnimationFrame(() => render(model));
}

// Tries to find a face in an image so we can texure map it into the life feed face.
async function getFace(model, texture) {
  const faces = await model.estimateFaces({ input: texture.image });
  if (!faces.length) {
    throw new Error('No face detected!');
  }
  // Get the found face and turn into texture coordinates.
  const face = faces[0];
  for (let j = 0; j < face.scaledMesh.length; j += 1) {
    face.scaledMesh[j][0] /= texture.image.naturalWidth;
    face.scaledMesh[j][1] /= texture.image.naturalHeight;
  }
  return { texture, face: faces[0] };
}

// Init the App, loading dependencies.
async function init(status, video) {
  // Load the MediaPipe Facemesh package.
  const model = await FaceLandmarksDetection.load(
    FaceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
    {
      maxFaces: 1,
    },
  );

  const textures = {
    ao: await Textures.load('ao.jpg'),
    facemask: await Textures.loadRandom(),
  };
  referenceFace = await getFace(model, textures.ao);
  material.map = textures.facemask;

  status.innerHTML = 'Initializing';

  scene = new Scene();
  camera = new OrthographicCamera(1, 1, 1, 1, -1000, 1000);

  renderer = new WebGLRenderer({ antialias: true, alpha: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.getElementById('App').appendChild(renderer.domElement);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFSoftShadowMap;
  renderer.outputEncoding = sRGBEncoding;

  // Resize
  const w = video.videoWidth;
  const h = video.videoHeight;
  camera.left = -0.5 * w;
  camera.right = 0.5 * w;
  camera.top = 0.5 * h;
  camera.bottom = -0.5 * h;
  camera.updateProjectionMatrix();
  faceGeometry.setSize(w, h);
  const videoAspectRatio = w / h;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const windowAspectRatio = windowWidth / windowHeight;
  let adjustedWidth;
  let adjustedHeight;
  if (videoAspectRatio > windowAspectRatio) {
    adjustedWidth = windowWidth;
    adjustedHeight = windowWidth / videoAspectRatio;
  } else {
    adjustedWidth = windowHeight * videoAspectRatio;
    adjustedHeight = windowHeight;
  }
  renderer.setSize(adjustedWidth, adjustedHeight);

  scene.add(mask);

  // Add lights.
  const spotLight = new SpotLight(0xffffff, 0.5);
  spotLight.position.set(0.5, 0.5, 1);
  spotLight.position.multiplyScalar(400);
  scene.add(spotLight);

  spotLight.castShadow = true;
  spotLight.shadow.mapSize.width = 1024;
  spotLight.shadow.mapSize.height = 1024;
  spotLight.shadow.camera.near = 200;
  spotLight.shadow.camera.far = 800;
  spotLight.shadow.camera.fov = 40;
  spotLight.shadow.bias = -0.005;
  scene.add(spotLight);

  const ambientLight = new AmbientLight(0x404040, 0.5);
  scene.add(ambientLight);

  status.innerHTML = '';
  await render(model);
}

export default {
  init,
};
