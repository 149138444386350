import { TextureLoader } from 'three';

const ELEMENTS = ['earth', 'fire', 'metal', 'water', 'wood'];

async function load(filename) {
  // Create a loader.
  const loader = new TextureLoader();

  return loader.loadAsync(`./textures/${filename}`);
}

async function loadRandom() {
  let filename = '';
  ELEMENTS.forEach(() => { filename += Math.floor(Math.random() * 3); });
  filename += '.png';
  return load(filename);
}
export default {
  load,
  loadRandom,
  ELEMENTS,
};
