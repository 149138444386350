import { useEffect } from 'react';
import './App.css';
import {
  Button,
  Container, Grid,
} from '@material-ui/core';
import Main from './main';

function App() {
  useEffect(() => {
    const status = document.getElementById('status');
    const video = document.querySelector('video');
    navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: 4096 },
        height: { ideal: 3072 },
      },
      audio: false,
    })
      .then((stream) => {
        video.srcObject = stream;
        video.onloadeddata = () => Main.init(status, video).catch((err) => {
          status.innerHTML = `${err.name}: ${err.message}`;
        });
      })
      .catch((err) => {
        status.innerHTML = `${err.name}: ${err.message}`;
      });
  }, []);

  return (
    <div>
      <Container>
        <h1 className="title">中醫面診圖像辨識系統</h1>
        <p className="subtitle">科技部補助計畫案 NSC-109-2410-H-119-005</p>
      </Container>
      <Container maxWidth="sm">
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <img src="textures/level01.png" alt="輕度病徵" />
            <p>輕度病徵</p>
          </Grid>
          <Grid item xs={4}>

            <img src="textures/level02.png" alt="中度病徵" />
            <p>中度病徵</p>
          </Grid>
          <Grid item xs={4}>
            <img src="textures/level03.png" alt="重度病徵" />
            <p>重度病徵</p>
          </Grid>
        </Grid>
      </Container>

      <div id="App" className="App">
        <video autoPlay style={{ transform: 'scaleX(-1)' }} />
      </div>
      <div className="btn-container">
        <Button
          variant="contained"
          className="switch-btn"
          onClick={() => {
            const ctx = document.querySelector('canvas');
            ctx.style.display = ((ctx.style.display === 'block') ? 'none' : 'block');
          }}
        >
          Switch
        </Button>
        <p id="status">Loading</p>
      </div>
    </div>
  );
}

export default App;
